import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx  from 'clsx';
import { withNamespaces } from 'react-i18next';
import {
	Box, 
	Typography
} from '@material-ui/core';

import Star from '../../UI/svg/Star';
import SafeAndSecure from '../../UI/svg/SafeAndSecure';

function LogosTrustV3({ classes, t }) {

	return (
		<Box className={classes.logosContainer}>
			<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
				<Box className={classes.logoCell}>
					<SafeAndSecure />
				</Box>
				<Box className={clsx(classes.logoCell)}>
					<Box className={clsx(classes.logoTrustPilot)}>
						<Box display="flex" justifyContent="center">
							<Star />
							<Star />
							<Star />
							<Star />
							<Star />
						</Box>
						<Typography>
							{t('Over 5,000 5-Star Reviews!')}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

LogosTrustV3.propTypes = {
	classes: PropTypes.object,
	t:  PropTypes.func
};

export default withNamespaces()(memo(LogosTrustV3));