import iconCheckGreen from '../../../assets/images/icon-check-green.png';
import iconError from '../../../assets/images/icon-error.png';

export const useStyles = () => ({
	fsMd: {
		fontSize: 16,
	},
	paymentInfoLine: {
		marginBottom: 10,
		justifyContent: 'space-between',
	},
	paymentInfoContainer: {
		margin: '0 auto',
		background: 'transparent',
		maxWidth: 320,
		fontFamily: 'Spartan, sans-serif',
		'& $paymentInfoLine:nth-child(3)': {
			marginBottom: 15,
		}
	},
	paymentNotEligible: {
		color: '#283339',
		fontSize: 14,
		fontWeight: 600,
		'& a': {
			color: '#5058C8',
			textDecoration: 'underline',
			cursor: 'pointer',
		},
		'& span': {
			color: '#E52866',
		}
	},
	paymentInfoLabel: {
		fontSize: 14,
		lineHeight: '20px',
		color: '#283339',
		fontWeight: 700,
	},
	paymentInfoValueGrey: {},
	paymentInfoValue: {
		fontSize: 14,
		lineHeight: '18px',
		color: '#283339',
		fontWeight: 700,
		textAlign: 'right',
	},
	couponContainer: {
		alignItems: 'flex-start',
	},
	couponInitBtn: {
		color: '#A6A9AB',
		fontSize: 12,
		fontWeight: 600,
		cursor: 'pointer',
	},
	couponBtn: {
		color: '#fff',
		backgroundColor: '#ff8f00',
		border: 'none',
		padding: 5,
		fontSize: 12,
		fontWeight: 700,
		borderRadius: 6,
		textTransform: 'initial',
		lineHeight: '13px',
		maxHeight: '28px',
		boxShadow: 'none',
		marginTop: 1,
		marginLeft: 7,
		'&:hover': {
			backgroundColor: '#ff8f00',
			border: 'none',
		},
	},
	couponSection: {
		maxWidth: 350,
		'& .MuiOutlinedInput-root input.MuiOutlinedInput-input': {
			fontSize: 12,
			padding: 6
		}
	},
	couponSectionMessagesBox: {
		paddingTop: '0px !important',
		paddingBottom: '0px !important',
	},
	couponCodeMessage: {
		fontSize: 11,
		fontWeight: 600,
	},
	couponCodeSuccess: {
		position: 'relative',
		color: '#28D799',
		paddingLeft: 20,
		marginTop: 3,
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 0,
			left: 0,
			width: 16,
			height: 14,
			backgroundImage: `url(${iconCheckGreen})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
	},
	couponCodeError: {
		position: 'relative',
		color: '#E52866',
		paddingLeft: 20,
		marginTop: 3,
		'&:before': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			display: 'block',
			width: 15,
			height: 14,
			backgroundImage: `url(${iconError})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		}
	},

	paymentTotal: {
		color: '#283339',
		fontSize: 20,
		fontWeight: 700,
		lineHeight: '30px',
		marginTop: 5,
	},
	paymentInfoBilling:  {
		color: '#6D7173',
		fontSize: 11,
		fontWeight: 600,
		lineHeight: '16px',
	},
	lineThrough: {
		textDecoration: 'line-through',
	},
	textGreen: {
		color: '#28D799',
	},
	textGrey: {
		color: '#333333',
	},
	textBlue: {
		color: '#0254ad',
	},
	savingPercentBg: {

	},
	paymentInfoSavingContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: 10,
	},
	paymentInfoSaving: {
		fontWeight: 600,
		color: '#ffffff',
		background: '#28D799',
		maxWidth: 200,
		padding: '5px 10px',
		borderRadius: 30,
		display: 'inline-block',
		textAlign: 'center',
	},
	paymentInfoCCContainerV2: {
		'&$paymentInfoContainer': {
			maxWidth: '100%',
		},
		'& $lineThrough': {
			color: '#6d7173',
		},
	},
	paymentInfoPayPalContainerV2: {
		'&$paymentInfoContainer': {
			maxWidth: '100%',
			padding: '20px 0px 10px',
			borderRadius: 30,
			background: '#ffffff',
			margin: '0 auto',
		},
		'& $lineThrough': {
			color: '#6d7173',
		},
	},
	planRenew: {
		fontSize: 11,
		fontWeight: 600,
		color: '#6D7173',
		marginBottom: 10,
		textAlign: 'right',
	},
	errorIcon: {
		position: 'relative',
		top: 0,
		paddingLeft: 20,
		fontSize: 11,
		fontWeight: 600,
		color: '#E52866',
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: '-2px',
			left: 3,
			width: 15,
			height: 13,
			backgroundImage: `url(${iconError})`,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},

	},
	checkbox: {
		marginTop: '-9px',
	},
	checkboxError: {
		color: '#f44336',
	},
	errorText: {
		width: 'auto',
		color: '#f44336',
		fontSize: '0.75rem',
		lineHeight: 1,
	},
	termsCheckbox: {
		alignItems: 'flex-start',
	},
	terms: {
		fontSize: 12,
		lineHeight: '22px',
		display: 'inline-block',
		color: '#283339',
		fontWeight: 600,
		fontFamily: 'Spartan, sans-serif',
		'& a': {
			color: '#283339'
		}
	},
	paymentInfoSentry: {
		fontSize: 14,
		lineHeight: '20px',
		fontWeight: 700,
		background: '#F3F3FB',
		borderRadius: 6,
		padding: '12px 12px',
		marginLeft: '-12px',
		marginRight: '-12px',
	},
	paymentInfoAddSentry: {
		color:  '#ffffff',
		padding: '5px 20px',
		cursor: 'pointer',
		background: '#5058C8',
		borderRadius: '100px',
	},
	paymentInfoSeparator: {
		height: 1,
		background: '#E9EAEB',
		margin: '24px 0 20px 0',
	},
	'@media (max-width: 500px)': {
		couponContainer: {
			alignItems: 'flex-start',
			flexDirection: 'column',
		},
		paymentInfoSaving: {
			alignSelf: 'flex-end',
			marginTop: 5,
		},
	},
	'@media (max-width: 460px)': {
		couponSection: {
			width: '100%',
			maxWidth: '100%',
			marginBottom: 10,
		},
		paymentTotal: {
			fontSize: 18,
		},paymentInfoCCContainerV2: {
			'& $paymentTotal': {
				fontSize: 16,
			},
		},
		paymentInfoPayPalContainerV2: {
			'& $paymentTotal': {
				fontSize: 16,
			},
		},
	},
});