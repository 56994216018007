import iconError from '../../../assets/images/icon-error.png';

export const useStyles = (theme) => ({
	fsMd: {
		fontSize: 16,
	},
	btnLink: {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	paymentInfoContainer: {
		margin: 0,
		borderRadius: 8,
		maxWidth: '100%',
		fontSize: 14,
		lineHeight: '22px',
		color: '#93999C',
		fontWeight: 600,
		fontFamily: 'Spartan, sans-serif',
	},

	paymentNotEligible: {
		color: '#A01C47',
		fontSize: 14,
		fontWeight: 600,
		'& a': {
			textDecoration: 'underline',
			cursor: 'pointer',
		},
	},
	paymentInfoSaving: {
		fontSize: 16,
		fontWeight: 700,
		color: '#ffffff',
		background: '#12AF5A',
		width: '100%',
		padding: 10,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
	},
	lineThrough: {
		textDecoration: 'line-through',
	},
	discount: {
		fontSize: 16,
		lineHeight: '26px',
		color: '#283339',
		fontWeight: 700,
	},
	total: {
		color: '#283339',
		fontSize: 24,
		fontWeight: 700,
		lineHeight: '36px',
		marginBottom: 20,
	},
	textGreen: {
		color: '#28D799',
	},
	separator: {
		height: 0,
		borderBottom: '1px solid #E9EAEB',
	},
	billingInfo: {
		paddingLeft: 30,
		fontSize: 12,
		lineHeight: '13px',
		color: '#93999C',
		fontWeight: 500,
		marginTop: -4,
	},
	errorIcon: {
		position: 'relative',
		top: '5px',
		paddingLeft: 20,
		fontSize: 11,
		fontWeight: 600,
		color: '#E52866',
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: '-2px',
			left: 3,
			width: 15,
			height: 13,
			backgroundImage: `url(${iconError})`,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},

	},
	checkbox: {
		marginTop: '-9px',
	},
	checkboxError: {
		color: '#f44336',
	},
	errorText: {
		position: 'absolute',
		top: 50,
		left: 0,
		width: 'auto',
		color: '#f44336',
		fontSize: '0.75rem',
		lineHeight: 1,
	},
	termsContainer: {
		position: 'relative',
		textAlign: 'center',
	},
	termsCheckbox: {
		alignItems: 'flex-start',
	},
	terms: {
		fontSize: 12,
		lineHeight: '19px',
		display: 'inline-block',
		color: '#93999C',
		fontWeight: 600,
		'& a': {
			textDecoration: 'underline',
			color: '#93999C'
		}
	},
	submit: {
		margin: theme.spacing(1, 0, 1),
		color: '#fff',
		backgroundColor: '#ff8f00',
		border: 'none',
		padding: 10,
		fontSize: 16,
		fontWeight: 700,
		borderRadius: 50,
		fontFamily: 'Spartan, sans-serif',
		textTransform: 'initial',
		lineHeight: '40px',
		maxWidth: 240,
		minWidth: 240,
		'&:hover': {
			backgroundColor: '#f18f00',
			border: 'none',
		},
	},
	info: {
		fontSize: 12,
		fontWeight: 500,
		lineHeight: '19px',
		color: '#283339',
	},
	moneyBack: {
		padding:  24,
		background:  '#D2F8E0',
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},
	moneyBackTitle: {
		color: '#283339',
		fontWeight: 700,
		fontSize: 16,
		lineHeight: '26px',
		marginBottom: 8,
	},
	moneyBackContent:  {
		color: '#525B60',
		fontWeight: 600,
		fontSize: 12,
		lineHeight: '19px',
	},
	amexWarning: {
		'& $errorIcon': {
			fontSize: 14,
			fontWeight: 700,
			display: 'block',
			'&::before': {
				top: 1,
			},
		}
	},
	paymentInfoSentry: {
		color: '#283339',
		fontSize: 14,
		lineHeight: '22px',
		fontWeight: 600,
		padding: '12px 0px',
	},
	paymentInfoAddSentry: {
		color:  '#ffffff',
		padding: '5px 20px',
		cursor: 'pointer',
		background: '#5058C8',
		borderRadius: '100px',
	},
	'@media (max-width: 500px)': {
		paymentInfoSaving: {
			alignSelf: 'flex-end',
			marginTop: 5,
		},
	},
	'@media (max-width: 460px)': {
		paymentTotal: {
			fontSize: 18,
		},
	},
});