import React from 'react';

function Star() {

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffc700" className="pi pi-star-fill" viewBox="0 0 16 16">
			<path d="m10.645 5.147-1.222-3.67c-.455-1.369-2.39-1.369-2.846 0l-1.222 3.67H1.854c-1.417 0-2.044 1.784-.94 2.67l2.804 2.249-1.226 3.685c-.458 1.376 1.108 2.536 2.29 1.697L8 13.166l3.217 2.282c1.183.84 2.749-.321 2.291-1.697l-1.226-3.685 2.803-2.249c1.105-.886.478-2.67-.939-2.67h-3.501Z"/>
		</svg>
	);
}

export default Star;